
.fbodym {
    clear:both;
    width: 1200px;
    background-color: #ffffff;
    align-items: center;
    text-align: center;
    margin-left: calc(50% - 600px);
    min-height: 800px;

}
.banimg{ height: 100px;}