
html,body {
  clear:both;
   width: 100%; left: 0; right: 0;
   align-items:left; text-align:left;
   background-color: #cfe7c514;
 }
.editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
  }
 .selectcss{ width:120px;text-align: center !important; align-items: center !important;}
 .inputr{ width:300px;}