html,
body {
  clear: both;
  width: 100%;
  left: 0;
  right: 0;
  align-items: center;
  text-align: center;
  background-color:#595959;
}

.foots {
  border-top: 10px solid red;
  background-color: #490aaf;
  // bottom: 0;
  height: 100px;
  width: 100%;
  align-items: center;
  text-align: center;
}
.footurl{
  color: #383838;
  text-decoration: none;
} /* 未访问的链接 */
 .foot a:link {
  color: #383838;
  text-decoration: none;
} /* 未访问的链接 */
.foot a:visited {
  color: #383838;
  text-decoration: none;
} /* 已访问的链接 */
.foot a:hover {
  color: #f00;
  text-decoration: underline;
} /* 鼠标移动到链接上 */
.foot a:active {
  color: #f0f;
} /* 选定的链接 */

.foot .footcopyr {
  background-color:#595959;
  // clear:both;
  line-height: 3em;
  font-size: 1.2em;
}
.fhead{
  width: 1200px;
  top: 0;
  z-index: 999;
  background: hsl(0, 0%, 100%);
  align-items:justify; text-align:justify;
}
.hmenu{  margin-left: 500px;}