
.editor {
    width: calc( 100% - 218px );
    height: calc( 100% - 50px );
    position: absolute;

    &-left,
    &-right {
        position: absolute;
        width: 218px;
        top: 0;
        bottom: 0;
    }

    &-left {
        
        left: 0;
        &-item{
            width: 180px;
            align-items: center;
            margin: 10px auto;
            display: flex;
            justify-content: center;
            background: hsla(150, 77%, 57%, 0);
            padding: 1px;
            box-sizing: border-box;
            cursor: move;
            user-select: none;
            min-height:60px;
            position: relative;
            border: 1px solid #ccc;
            > span{
                position: absolute;
                top: 0;
                left: 0;
                color: hsl(0, 17%, 8%);
                padding: 5px;
                width: 80%;
            }
            &::after{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity:0.2;




            }




        }
    }

    &-right {
        right: 0;
    }

    &-top {
        position: absolute;
        right: 228px;
        left: 228px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(219, 219, 214, 0.064);
        &-button{width: 60px;
            height: 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: rgb(0,0,0,.3);
            user-select: none;
            cursor: pointer;
            color: #fff;
            &+&{margin-left: 3px;}
        }
    }

    &-container {
        padding: 60px 270px 0;
        height: 100%;
        box-sizing: border-box;
        
        &-canvas {
            overflow: scroll;
            height: 100%;
            

            &-content {
                 margin: 20px auto;
                 position: relative;
                background-color: #ffffff;/*背景色*/
			background-image: linear-gradient(90deg, rgba(218, 190, 190, 0.15) 10%, rgba(248, 246, 246, 0) 10%) 
			,linear-gradient(0deg,rgba(218, 190, 190, 0.15) 10%, rgba(0, 0, 0, 0) 10%);/*rgba(0, 0, 0, 0)，transparent全透明黑*/
			background-size: 15px 15px;/*调节格子宽 高*/

            }

        }


    }
}
.editor-block{ position:relative;
    // &::after{
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    // }

}
.editor-block-focus{
    &::after{
        border: 1px dashed red;
    }

}
.editor-block-preview{
    &::after{
        display: none;
    }
}
//移动线
.line-x{
    position: absolute;
    top:0;
    bottom: 0;
    border-left: 1px dashed red;
}
.line-y{
    position: absolute;
    left:0;
    right: 0;
    border-top: 1px dashed red;
}









// dropdown
.dropdown{
    display: none;
    position: absolute;
    background: #fff;
    box-shadow: 2px 2px #ccc;
}
.dropdown-isShow{
    display: block;
}

.dropdown-item{
    line-height: 30px;
    width: 100px;
    border-bottom: 1px solid #ccc;
    text-align: center;
    user-select: none;
    cursor: pointer;
}


.range{
    display: inline-flex;
    width: 220px;
    min-height: 30px;
    align-items: center;
    input{
        flex:1;
        width: 100%;
       
    }
    span{
         margin: 0 4px;
         display: inline-flex;
    }
}



.block-resize{
    position: absolute;
    width: 8px;
    height: 8px;
    background:rgb(15, 65,204);
    z-index: 1000;
    user-select: none;
}
.block-resize-top{
    top: -4px;
    left: calc( 50% - 4px );
}

.block-resize-bottom{
    bottom: -4px;
    left: calc( 50% - 4px );
}
.block-resize-left{
    top:calc( 50% - 4px );
    left: -4px;

}
.block-resize-right{
    top:calc( 50% - 4px );
    right: -4px;
}

.block-resize-top-left{
 top: -4px;
 left: -4px;
}
.block-resize-top-right{
    top: -4px;
    right: -4px;
}

.block-resize-bottom-left{
    bottom: -4px;
    left:  -4px;
}
.block-resize-bottom-right{
    bottom: -4px;
    right: -4px;
}