
.fbodym {
  width: 1200px;
  align-items: left;
  text-align: left;
  padding: 10px 10px 10px 10px;
  background-color:#b62020;

}

.wxkf {
  text-decoration: none;
}
.nrcenter{ align-items: center;text-align: center;}
.nrleft{ align-items: left;text-align: left;}
.zmd{ 
  height: 260px; 
  width: 1200px; 
  padding: 0 15px;
  margin-bottom: 15px;
}
.zmdimg{
  height: 260px; 
   width: 1180px;}

.tdiv{
	width:100px;
	height:100px;
	background:red;
	position:relative;
	animation:myfirst 20s;
  animation-iteration-count: infinite;
	-webkit-animation:myfirst 20s; /* Safari and Chrome */
  -webkit-animation-iteration-count: infinite;
}


@keyframes myfirst {
	0%   {background:red; left:300px; top:-300px; width:100px;height:100px;}
	25%  {background:yellow; left:900px; top:-300px;width:200px;height:200px;}
	50%  {background:blue; left:900px; top:300px;width:100px;height:100px;}
	75%  {background:green; left:300px; top:300px;width:200px;height:200px;}
	100% {background:red; left:300px; top:-300px;width:100px;height:100px;}
}

@-webkit-keyframes myfirst {
	0%   {background:red; left:300px; top:-300px; width:100px;height:100px;}
	25%  {background:yellow; left:900px; top:-300px;width:200px;height:200px;}
	50%  {background:blue; left:900px; top:300px;width:100px;height:100px;}
	75%  {background:green; left:300px; top:300px;width:200px;height:200px;}
	100% {background:red; left:300px; top:-300px;width:100px;height:100px;}
}
.btfontcss{
  font-size: 2.8em;-webkit-text-fill-color:#f8f8f8;-webkit-text-stroke: 1.5px hwb(226 16% 9% / 0.849); text-shadow: 5px 3px 10px #5e5959;
  font-weight: bolder; letter-spacing: 0.1em;
  margin-bottom: 15px; margin-top: 15px;
}
.titlecs{
  align-items: left;text-align: left;
  padding: 5px 5px 5px 15px; font-size: 1.1em;letter-spacing: 0.05em; color: #434343;
  // background-color: #e6f4ff; 
  background-image: linear-gradient(to right, #91caff, #ffffff);
  border-radius: 0 0 0 0px;
}
.con_one{
height: 100px;
align-items: left;text-align: left;
}
.con_sone{
  height: 300px;
}
.skkk{
 height: 500px;
}
.smclass{
  color:#434343;
}